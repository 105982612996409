/*import { isInView, isOutOfView } from 'isinview'

const options = {
  threshold: 1
}

isInView(document.querySelectorAll('.item--animated'), (target) => {
    target.classList.add('is-visible')
  },
  options
)

isOutOfView(document.querySelectorAll('.item--animated'), (target) => {
  target.classList.remove('is-visible')
})*/



import $ from "jquery";

// import inView from "in-view";
import Wiscroll from 'wiscroll';
require('paroller.js');

$(document).ready(function() {
	if (window.innerWidth > 300) {

		$('[data-inview]').each(function(index, el) {

			new Wiscroll(el).on('60% top', 'isInView')
		});

		$("[data-parrallax]").paroller({
			factor: -0.06,
			factorXs: -0.05,
			type: 'foreground',
			direction: 'vertical',
			transition: 'transform 0.2s ease'
		});

	}
});
