import "./import/modules";
import "./import/components";

global.$ = global.jQuery = require('jquery');

function handleInputDevice(event) {
  document.body.dataset.input =
    event.type === 'mousedown' ? 'mouse' : 'keyboard';
}

window.addEventListener('mousedown', handleInputDevice, false);
window.addEventListener('keydown', handleInputDevice, false);
