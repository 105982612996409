import Swiper, { Navigation, Pagination} from 'swiper'

//require ("swiper/swiper-bundle.css");

$(document).ready(function () {

  var galleryMobile = new Swiper ('.gallery-mobile', {
    modules: [Pagination, Navigation],
    slidesPerView: 1,
    spaceBetween: 0,
    centeredSlides: true,

    pagination: {
       el: '.mobile-gallery-pagination',
       type: 'bullets',
       clickable: true,
     },

    navigation: {
      nextEl: ".mobile-gallery-button-next",
      prevEl: ".mobile-gallery-button-prev",
    },
  });

  var galleryDesktop = new Swiper ('.gallery-desktop', {
    modules: [Pagination, Navigation],
    slidesPerView: 1,
    spaceBetween: 0,

    pagination: {
       el: '.desktop-gallery-pagination',
       type: 'bullets',
       clickable: true,
     },

    navigation: {
      nextEl: ".desktop-gallery-button-next",
      prevEl: ".desktop-gallery-button-prev",
    },
  });

});
