import $ from "jquery";
window.$ = jQuery;

$(document).ready(function () {

  $('.hamburger').click(function () {
    $(this).toggleClass('hamburger--js');
    $('.header-menu').toggleClass('header-menu--js');
    $('.header-logo').toggleClass('header-logo--js');
    $('body').toggleClass('fixed');
  });

});
